<template>
  <Loading v-if="isLoading" />
  <div v-else class="platforms-state-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("platforms.platforms_state") }}</h1>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'platform'" class="text-nowrap">
            {{ $t("platforms.platform") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("platforms.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("platforms.actions") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span class="text-nowrap" v-if="props.column.field === 'platform'">
            {{ handleTilte(props.row.key) }}
          </span>

          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>

          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === 'value'"
          >
            <b-form-checkbox
              switch
              v-model="props.row.value.value"
              @change="(e) => handlePlatformVisibility(e, props.row.key)"
              :value="1"
              :unchecked-value="0"
            />
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <!-- <b-dropdown-item @click="editplatforms(props.row)"> -->
                <b-dropdown-item
                  :to="{ name: 'edit-platform', params: { id: props.row.key } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <overlay-component :isLoading="overlayLoading" />
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import OverlayComponent from "@/components/shared/OverlayComponent";
import Loading from "@/components/shared/loading/loading.vue";

export default {
  name: "PlatformsStateList",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    BButton,
    Loading,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    OverlayComponent,
  },
  data() {
    return {
      isLoading: true,
      pageLength: 20,
      overlayLoading: false,
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "platform",
          field: "platform",
        },
        {
          label: "status",
          field: "value",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
      keys: [
        "in_maintenance_website",
        "in_maintenance_app",
        "in_maintenance_vendor",
        "in_maintenance_delivery",
      ],
    };
  },
  beforeMount() {
    this.getPlatformsList();
  },
  methods: {
    async getPlatformsList() {
      this.keys.map((item) => {
        this.$http
          .get(`admin/settings?key=${item}`)
          .then((res) => {
            if (res.status === 200) {
              this.tableBody.push(res.data.data);
              this.tableBody.map((el, index) => {
                (el.index = index + 1), (el.value.value = +el.value.value);
              });
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            this.$helpers.handleError(err);
          });
      });
    },
    async handlePlatformVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          value: checked,
        };
        const res = await this.$http.post(
          `admin/settings/${id}?_method=put`,
          data
        );
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    handleTilte(key) {
      switch (key) {
        case this.keys[0]:
          return this.$t("platforms.web_site");
        case this.keys[1]:
          "in_maintenance_app";
          return this.$t("platforms.mobile_app");
        case this.keys[2]:
          "in_maintenance_vendor";
          return this.$t("platforms.vendor");
        case this.keys[3]:
          "in_maintenance_delivery";
          return this.$t("platforms.delivery_app");
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
